import React, { useState, useEffect, useDebugValue } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/faq.css"

import FaqPageBanner from "../images/frequently-asked-questions.svg"
import faq_data from "../data/faq.json"

const toggleFAQContent = event => {
  let target = event.target
  console.log("target is", target)
  let isChecked = target.querySelector('input[type="checkbox"]').checked
  console.log("isChecked", isChecked)
  target.querySelector('input[type="checkbox"]').checked = !isChecked
}

const FaqPage = () => {
  const all_faqs = faq_data.map((o, i) => {
    o.isChecked = true
    o.id = i + 1
    return o
  })
  const [faqs, updateFaqs] = useState(all_faqs)

  const toggleCheck = dataObj => event => {
    let elem = event.target
    let modified = [...faqs]
    let index
    modified.forEach((o, i) => {
      if (o.id == dataObj.id) index = i
    })
    modified[index] = {
      ...dataObj,
      isChecked: !dataObj.isChecked,
    }
    updateFaqs(modified)
  }

  return (
    <Layout>
      <SEO title="FAQ Page" />
      <section className="section-intro">
        <div className="section--content align-items-center">
          <div className="home--left--section">
            <h1 className="home--banner-text">Frequently asked questions.</h1>
            <h2 className="home--banner-text-sub">Find your answer below</h2>
            <h4 className="home--banner-text-small">
              Feeling lost? You are not alone we have compiled <br /> a list of
              frequently asked questions below.
            </h4>
          </div>
          <div className="home--right--section">
            <div className="home-delivery--illustration">
              <img src={FaqPageBanner} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="faq-section faq-section-content">
        <h2 className="section--content--header center">
          Frequently asked questions
        </h2>
        <div className="section--content">
          <div className="faq-container">
            <h2 className="faq header">new to muvit?</h2>
            <ul>
              {faqs
                .filter(o => o.type == "customer")
                .map(o => (
                  <li key={o.id}>
                    <input
                      type="checkbox"
                      onChange={toggleCheck(o)}
                      checked={o.isChecked}
                    />
                    <i></i>
                    <h2>{o.question}</h2>
                    <p dangerouslySetInnerHTML={{ __html: o.answer }} />
                  </li>
                ))}
            </ul>
          </div>
          <div className="faq-container">
            <h2 className="faq header">Information for recipients</h2>
            <ul>
              {faqs
                .filter(o => o.type == "recipient")
                .map(o => (
                  <li key={o.id}>
                    <input
                      type="checkbox"
                      onChange={toggleCheck(o)}
                      checked={o.isChecked}
                    />
                    <i></i>
                    <h2>{o.question}</h2>
                    <p dangerouslySetInnerHTML={{ __html: o.answer }} />
                  </li>
                ))}
            </ul>
          </div>
          <div className="faq-container">
            <h2 className="faq header">Become our partner</h2>
            <ul>
              {faqs
                .filter(o => o.type == "partner")
                .map(o => (
                  <li key={o.id}>
                    <input
                      type="checkbox"
                      onChange={toggleCheck(o)}
                      checked={o.isChecked}
                    />
                    <i></i>
                    <h2>{o.question}</h2>
                    <p dangerouslySetInnerHTML={{ __html: o.answer }} />
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default FaqPage
